.MobileNav {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;

  &-nav {
    align-items: center;
    position: fixed;
    right: 0;
    width: 100%;
    background-color: $color--algo-gray;
    z-index: 100;
  }

  &-logo {
    margin: space(1.5) space(2);
    height: space(5);
  }

  &-list {
    padding-bottom: space(4);
  }

  &-item {
    display: block;
    padding: space(3);
    font-family: $font-family--primary;
    color: $color--white;
    text-decoration: none;
    font-size: space(2);
    text-align: center;
  }
}
