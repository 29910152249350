.Contact {
  position: relative;
  background-color: $color--algo-gray;
  padding: space(8) 0 space(8) 0;
  margin-top: space(0);
  overflow: hidden;
  z-index: 2;

  &-info {
    position: relative;
  }

  &-background {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #313131;
    border-radius: $border-radius--small;
    // transform: translate(-80px, 20px);
    z-index: -1;
  }

  &-header {
    color: $color--white;
  }

  &-email {
    color: $color--white;
    margin-bottom: space(1);
    font-style: italic;
  }

  &-about {
    color: $color--white;
    line-height: 1.3;
  }

  &__github,
  &__linkedin {
    background-color: #8e8f8e;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: space(1);
    margin-bottom: space(4);
    padding: space(1);
    border-radius: 100px;
    height: rem(40px);
    width: rem(40px);
    transform: translateY(3px);
    box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.4);
  }

  &__icon {
    height: space(2);
  }

  &-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: space(10);
    color: $color--white;
    border-radius: $border-radius--small;
  }

  &-name-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 space(2);
  }

  &-label {
    display: block;
    margin-bottom: space(1);
    color: $color--white;
  }

  &-input {
    width: 100%;
    margin-bottom: space(2);
    height: rem(40px);
    padding: space(1) space(2);
    border-radius: $border-radius--small;
    color: $color--algo-gray;
    background-color: $color--algo-xtra-light-gray;
    box-shadow: 0px 4px 15px -2px rgba(0, 0, 0, 0.4);
  }

  &-textarea {
    height: space(22);
  }

  &-button {
    display: flex;
    align-items: center;
    padding: space(1) space(3);
    border-radius: 100px;
    background: linear-gradient(266.05deg, #b62027 9.87%, #aa286c 89.34%);
    color: $color--light-gray;
    box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.4);
  }

  &-button-text {
    font-family: $font-family--secondary;
    padding-left: space(1);
  }
}
