.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-logo {
    height: space(7);
    padding: space(1) 0;
  }

  &-nav {
    display: flex;
    align-items: center;

    @media (max-width: 838px) {
      display: none;
    }
  }

  &-linkResume,
  &-linkAlgo {
    font-size: rem(16px);
    font-family: $font-family--secondary;
    text-transform: capitalize;
    color: $color--white;
  }

  &-linkResume {
    padding: space(3);
  }

  &-linkAlgo {
    padding: space(3) 0 space(3) space(3);
  }

  &-hamburger {
    position: relative;
    z-index: 102;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 35px;
    width: 35px;
    overflow: hidden;

    @media (min-width: 839px) {
      display: none;
    }
  }

  &-line {
    &__top,
    &__middle,
    &__bottom {
      height: 2px;
    }

    &__middle {
      width: 75%;
      align-self: flex-end;
    }
  }
}

.header-light .header {
  &-linkAlgo,
  &-linkResume {
    color: $color--black;
  }
}
