/*-------------------------------*/
/* FONTS */
/*-------------------------------*/
$font-url--google: "https://fonts.googleapis.com/css2?family=Karla&family=Rubik:wght@900&display=swap";

$font-family--primary: "Karla", sans-serif;
$font-family--secondary: "Rubik", sans-serif;

// $font-weight--light: 300;
// $font-weight--regular: 400;
// $font-weight--semi-bold: 600;
// $font-weight--bold: 700;

/*-------------------------------*/
/* BREAKPOINTS */
/*-------------------------------*/

// $breakpoint--lg: 1200px;
// $breakpoint--md: 716px;
// $breakpoint--sm: 300px;

/*-------------------------------*/
/* COLORS */
/*-------------------------------*/

$color--white: #fff;
$color--black: #1f1f1f;
$color--off-black: rgb(27, 27, 27);
$color--light-gray: #f5f5f5;

// algorithms section

$color--algo-gray: #3e3e3e;
$color--algo-light-gray: #737374;
$color--algo-xtra-light-gray: #c4c4c4;
$color--algo-white: #f3f3f3;

/*-------------------------------*/
/* IMAGE PATH */
/*-------------------------------*/

$img-path: "../images";

/*-------------------------------*/
/* BORDER RADIUS */
/*-------------------------------*/

$border-radius--small: 4px;
$border-radius--circle: 100px;

/*-------------------------------*/
/* SPACING */
/*-------------------------------*/

// $spacing--xtra-small: 15px;
// $spacing--small: 25px;
// $spacing--large: 80px;
// $spacing--cta-btn: 15px 25px;

/*-------------------------------*/
/* Z-INDEX */
/*-------------------------------*/

$index--1: 1;
//.hero-grid1

// $index--2: 2;
// .mobile-nav-background

// $index--3: 3;
// .header
