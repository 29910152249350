.Success {
  position: relative;
  min-height: 100vh;
  background-color: $color--algo-gray;

  &-messageContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }

  &-header {
    color: $color--algo-xtra-light-gray;
    margin-bottom: space(4);
  }

  &-btn {
    padding: space(1.5) space(3);
    border-radius: $border-radius--circle;
    font-family: $font-family--secondary;
    background: linear-gradient(266.05deg, #b62027 9.87%, #aa286c 89.34%);
    color: $color--light-gray;
    box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.4);
  }

  &-linkContainer {
    display: inline-block;
  }
}
