.hero {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-bottom: space(18);
  padding-top: space(12);
  width: 100%;

  @media (max-width: 838px) {
    grid-template-rows: auto auto;
    padding-top: space(9);
    padding-bottom: space(25);
    row-gap: space(5);
  }

  &-outer {
    display: flex;
    align-items: center;
  }

  &-grid1 {
    grid-column-start: 1;
    grid-column-end: 12;
    grid-row-start: 1;
    grid-row-end: 2;
    z-index: 1;
    margin-top: space(9);

    @media (max-width: 838px) {
      grid-column-start: 1;
      grid-column-end: 13;
      grid-row-start: 1;
      grid-row-end: 2;
      margin-top: space(2);
    }
  }

  &-heading {
    margin-bottom: space(1);

    @media (max-width: 595px) {
      font-size: space(4);
    }
  }

  &-underline {
    position: relative;
    font-family: $font-family--secondary;
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: 6px;
      left: 0;
      height: space(1);
      width: 100%;
      background: linear-gradient(266.05deg, #b62027 9.87%, #aa286c 89.34%);
      border-radius: 1px;

      @media (max-width: 595px) {
        bottom: 2px;
      }
    }
  }

  &-subheading {
    margin-bottom: space(8);
    font-size: rem(16px);
  }

  &-grid2 {
    position: relative;
    grid-column-start: 9;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 2;
    border-radius: $border-radius--small;

    @media (max-width: 838px) {
      margin-top: space(5);
      grid-column-start: 1;
      grid-column-end: 13;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }

  &-headshot {
    width: 100%;
    padding: 0 space(2);

    @media (max-width: 838px) {
      width: 100%;
      margin-top: space(4);
      justify-self: center;
    }
  }

  &-btn {
    padding: space(1.5) space(3);
    border-radius: $border-radius--circle;
    font-family: $font-family--secondary;
    background: linear-gradient(266.05deg, #b62027 9.87%, #aa286c 89.34%);
    color: $color--light-gray;
    box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.4);
  }

  &-background {
    position: absolute;
    background-color: white;
    height: 100%;
    width: 100%;
  }
}
