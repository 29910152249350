.SkillsSection {
  background-color: $color--white;
  border-radius: $border-radius--small;
  padding: space(4) space(0);
  overflow: hidden;
  box-shadow: 0px 4px 15px -2px rgba(0, 0, 0, 0.1);
}

.headerMarquee {
  width: 1100px;
  overflow: hidden;
  position: relative;
  height: rem(20px);
  margin-bottom: space(2);

  &__zero {
    position: absolute;
    width: 100%;
    left: 0;
    transform: translateX(0%);
    display: flex;
    justify-content: space-between;
    padding: 0 space(2.8);
  }

  &__one,
  &__two,
  &__three,
  &__four {
    position: absolute;
    width: 100%;
    left: 0;
    transform: translateX(-100%);
    display: flex;
    justify-content: space-between;
    padding: 0 space(2.8);
  }

  &__item {
    opacity: 0.5;
  }

  &__zero {
    animation: marqueeHeaderZero 40s linear forwards;
  }

  &__one {
    animation: headerMarquee 40s linear 0s infinite;
  }

  &__two {
    animation: headerMarquee 40s linear 10s infinite;
  }

  &__three {
    animation: headerMarquee 40s linear 20s infinite;
  }

  &__four {
    animation: headerMarquee 40s linear 30s infinite;
  }
}

.marquee {
  width: 1100px;
  height: 70px;
  overflow: hidden;
  position: relative;
  font-size: 45px;

  &__zero {
    position: absolute;
    width: 100%;
    left: 0;
    transform: translateX(0%);
    display: flex;
    justify-content: space-between;
  }

  &__one,
  &__two,
  &__three,
  &__four {
    position: absolute;
    width: 100%;
    left: 0;
    transform: translateX(100%);
    display: flex;
    justify-content: space-between;
  }

  &__item {
    opacity: 0.5;
    min-width: 70px;
    text-align: center;
  }

  &__text {
    font-size: rem(10px);
    text-align: center;
  }

  &__logo--nonReactIcons {
    height: 45px;
    width: 45px;
  }

  &__zero {
    animation: marqueeZero 40s linear forwards;
  }

  &__one {
    animation: marquee 40s linear 0s infinite;
  }

  &__two {
    animation: marquee 40s linear 10s infinite;
  }

  &__three {
    animation: marquee 40s linear 20s infinite;
  }

  &__four {
    animation: marquee 40s linear 30s infinite;
  }
}

@keyframes marqueeHeaderZero {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(200%);
  }
}

@keyframes marqueeZero {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-200%);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes headerMarquee {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
