.Resume {
  min-height: 100vh;
  background-color: $color--algo-gray;
  padding-bottom: space(4);

  &-container {
    position: relative;
    max-width: rem(900px);
    margin: 0 auto;
  }

  &-img {
    width: 100%;
  }

  &-downloadBtn {
    display: block;
    position: absolute;
    bottom: space(4);
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(89.77deg, #2e6e96 0.1%, #3785b6 99.9%);
    padding: space(1.5) space(3);
    border-radius: 100px;
    color: $color--light-gray;
    transition: 0.1s ease-in-out;

    &:hover {
      transform: translateX(-50%) scale(1.08);
    }

    @media (max-width: 445px) {
      bottom: space(1);
      text-align: center;
    }
  }
}
